import React from 'react';

const fileUrl = "https://www.cadandrender.com/files";

const HomeBanner = () =>{
    return (
        <>
        <div className='cadBannerHome'>
            <video className='videoTag' autoPlay loop muted>
                <source src={`${fileUrl}/ringvideo.mp4`} type='video/mp4' />
            </video>
        </div>
        </>
    );
}

export default HomeBanner;