import React,{useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import Contactform from '../components/Contactform';
import HeaderSec from '../components/HeaderSec';
import Footer from '../components/Footer';
import PaymentMethods from '../components/PaymentMethods';
import CookiesPopup from '../components/CookiesPopup';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';
import RealisticGallery from '../components/RealisticGallery';
import CadModelingEditingGallery from '../components/CadModelingEditingGallery';
import GalleryVideos from '../components/GalleryVideos';
import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const Gallery = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
    return (
        <>
        <Title title="Gallery | CAD AND RENDER" />
        <HeaderSec />
        <ContactIcon />
        <WhatsappChat />
        <ScrollTop />
        <CookiesPopup />

        <div className='cadGalleryBanner cadAboutBanner cadContactBanner'>
            <img 
            src={`${fileUrl}/gallery-banner.webp`}
            alt="gallery-banner" />
            <div className='container'>
                <div className='cadContactBannerContent'>
                    <h1>Gallery</h1>
                </div>
            </div>
        </div>

        <div className='cadGallerycontent cadAboutContent'>
            <div className='container'>
                <p>At CAD and RENDER, we specialize in providing high-quality jewelry 3D CAD Designing and Realistic Rendering services to jewelry manufacturers, wholesalers and retailers. With years of experience in the industry, we have become experts in our craft and are dedicated to providing exceptional service to our clients.</p>
            </div>
        </div>

        <div className='cadGalleyImagesMain cadAbout'>
            <div className='container'>
                <h2>IMAGE GALLERY</h2>

                <div className='cadGalleryRealistic'>
                    <h3>Realistic Images</h3>
                    <RealisticGallery />
                </div>

                <div className='cadGalleryCadModeling-CadEditing'>
                    <h3>CAD Modeling <span>&</span>CAD Editing</h3>
                    <CadModelingEditingGallery />
                </div>
                
            </div>
        </div>

        <div className='cadGalleyVideosMain cadAbout'>
            <div className='container'>
                <h2>VIDEO GALLERY</h2>
                <GalleryVideos />
            </div>
        </div>

        <div className='cadContactForm cadAbout'>
            <div className='container'>
                <h2>CONTACT US</h2>
            </div>
            <div className='row cadContactFormAlign'>
                <div className='cadContactFormContent cadServicesListContent col-6'>
                    <div className='cadContactFormImg cadServicesInnerImage'>
                        <NavLink to="/contact-us">
                            <img 
                            src={`${fileUrl}/gallery-contact-form.webp`}
                            alt="gallery-contact-form" />
                        </NavLink>
                    </div>
                </div>
                <Contactform />
            </div>
        </div>

        <PaymentMethods />

        <Footer />
        </>
    );
}


export default Gallery;