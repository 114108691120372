import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ReactWhatsapp from 'react-whatsapp';

const WhatsappChat = () => {

    const [whatsappNum, setWhatsappNum] = useState([]);

    useEffect(()=>{
        axios.get("https://www.cadandrender.com/adminPanel/API/contact.php").then((response)=>{
            setWhatsappNum(response.data.Contact);
      })
    },[]);

    return(
        <>
        <div className='WhatsappChatIcon'>
        {whatsappNum.map((res)=>
        <>
            <ReactWhatsapp number={res.CadContact_WpNumber} message="Hello Mr. Kunal...">
                <i className="fa fa-whatsapp WhatsAppIcon"></i>
            </ReactWhatsapp>
        </>
        )}
        </div>
        </>
    );
}


export default WhatsappChat;