import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Toolbarfull from './Toolbarfull';
import Toolbarmobile from './Toolbarmobile';

const fileUrl = "https://www.cadandrender.com/files";

const Header = () => {
   
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

        return(
            <>
                <div className={`cadHeaderMain ${isSticky ? 'sticky' : ''}`}>
                    <div className='container'>
                        <div className='row cadHeaderAlign'>
                            <div className='cadHeaderNavSampleBtn col-1'>
                                <div className='cadHeaderSampleBtn'>
                                    <NavLink to="/contact-us">Queries?</NavLink>
                                </div>
                            </div>
                            <div className='cadHeaderNavMenus col-'>
                                <ul>
                                    <li><NavLink to="/">HOME</NavLink></li>
                                    <li><NavLink to="/about-us">ABOUT</NavLink></li>
                                    <li className='dropdown'><NavLink to="/our-services">SERVICES <i className="fa fa-angle-down"></i></NavLink>
                                        <div className="dropdown-content">
                                            <li><NavLink to="/realistic-rendering">REALISTIC IMAGES</NavLink></li>
                                            <li><NavLink to="/animation-video">3D ANIMATIONS</NavLink></li>
                                            <li><NavLink to="/cad-modeling">3D CAD MODELING</NavLink></li>
                                            {/* <li><NavLink to="/model-prints">MODEL PRINTS</NavLink></li> */}
                                            <li><NavLink to="/retouching-and-editing">CAD EDITING</NavLink></li>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className='cadHeaderLogo col-3'>
                                <div className='cadHeaderLogoCenter'>
                                    <NavLink to="/">
                                        <img 
                                        src={`${fileUrl}/logo.png`}
                                        alt="cadandrender-logo" />
                                    </NavLink>
                                </div>
                                <div className='cadHeaderLogoCenterSticky'>
                                    <NavLink to="/">
                                        <img 
                                        src={`${fileUrl}/render-logo-black.png`}
                                        alt="cadandrender-logo" />
                                    </NavLink>
                                </div>
                            </div>
                            <div className='cadHeaderNavMenus col-'>
                                <ul>
                                    <li><NavLink to="/gallery">GALLERY</NavLink></li>
                                    {/* <li><NavLink to="/blogs">Blogs</NavLink></li> */}
                                    <li><NavLink to="/contact-us">CONTACT</NavLink></li>
                                    <li><NavLink to="/faq">FAQ</NavLink></li>
                                </ul>
                            </div>
                            <div className='cadHeaderMenu col-1'>
                                <div className='cadheader-full cadHeaderNav'>
                                    <Toolbarfull />
                                </div>
                                <div className='cadheader-mobile cadHeaderNav'>
                                    <Toolbarmobile />
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                
            {/* Top Header End  */}
            </>
        )
}


export default Header;