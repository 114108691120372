import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';

const Toolbarmobile = () =>{
    const [sidebar, setSidebar] = useState(false);

    const handleMenuClick = () => {
        setSidebar(!sidebar);
      };

    return(
        <>
        <div className='cadHeaderMenubar' onClick={handleMenuClick}>
            <span className="bars">
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className='bar3'></div>
            </span>
        </div>
        <div className={sidebar ? "cadHeadaerSidebarOpen cadHeaderSidebar": "cadHeaderSidebar"}>
            <div className='cadMenuClose'>
                <span className='closebtn' onClick={() => setSidebar(!sidebar)}>&#x2715;</span>
            </div>
            <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/about-us">About Us</NavLink></li>
                <li className='dropdown'><NavLink to="/our-services">Our Services <i className="fa fa-angle-down"></i></NavLink>
                    <div className="dropdown-content">
                        <li><NavLink to="/realistic-rendering">Realistic Images</NavLink></li>
                        <li><NavLink to="/animation-video">3D Animations</NavLink></li>
                        <li><NavLink to="/cad-modeling">3D CAD Modeling</NavLink></li>
                        <li><NavLink to="/retouching-and-editing">CAD Editing</NavLink></li>
                    </div>
                </li>
                <li><NavLink to="/gallery">Gallery</NavLink></li>
                {/* <li><NavLink to="/blogs">Blogs</NavLink></li> */}
                <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                <li><NavLink to="/faq">FAQ</NavLink></li>
            </ul>
        </div>
        </>
    );
}

export default Toolbarmobile;