import React,{useEffect} from 'react';
import Contactform from '../components/Contactform';
import HeaderSec from '../components/HeaderSec';
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';
import PaymentMethods from '../components/PaymentMethods';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';
import CookiesPopup from '../components/CookiesPopup';

import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const Ourservices = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
        return(
            <>
            <Title title="Services | CAD AND RENDER" />
            <HeaderSec />

            <ContactIcon />
            <WhatsappChat />
            <ScrollTop />
            <CookiesPopup />

            <div className='cadOurServices cadAboutBanner cadContactBanner'>
                <img 
                src={`${fileUrl}/services-banner.webp`}
                alt="cadandrender-services-banner" />
                <div className='container'>
                    <div className='cadContactBannerContent'>
                        <h1>OUR SERVICES</h1>
                    </div>
                </div>
            </div>

            <div className='cadAboutContent'>
                <div className='container'>
                    <p>At CAD and RENDER, we specialize in providing high-quality jewelry 3D CAD Designing and Realistic Rendering services to jewelry manufacturers, wholesalers and retailers. With years of experience in the industry, we have become experts in our craft and are dedicated to providing exceptional service to our clients.</p>
                </div>
            </div>

            <div className='cadServicesMain'>
                <div className='cadServicesMainInner servicesBlack row'>
                    <div className='cadServicesInnerLeft col-6'>
                        <div className='cadServicesInnerImage'>
                            <NavLink to="/realistic-rendering">
                                <img 
                                src={`${fileUrl}/services-realistic-img.webp`}
                                alt="Realistic-services"
                                 />
                            </NavLink>
                        </div>
                    </div>
                    <div className='cadServicesInnerRight cadAbout col-6'>
                        <h3>REALISTIC IMAGES</h3>
                        <p>After getting your 3D model ready the next step is to showcase your product to the whole world. Your customer wants to see your products just as they would appear in real life, the color of the gemstones and the metal along with the environment should be realistic and there is no better way to do it than to get them Rendered.</p>
                        <p>Whether you’re a worldwide renowned entity with a huge catalogue or an entrepreneur with a handful of designs, 3D Photorealistic Image Rendering will heighten your products’ appearance helping you in accomplishing your goals be it presentations, proposals or e-commerce sales.</p>
                        <p>Moreover, you can create endless combinations to beautify your products as while going through the Rendering process, our professional team considers all your preferences and choices to give you the perfect look for your item.</p>
                        <div className="cadAboutBtn">
                            <NavLink to="/realistic-rendering">READ MORE</NavLink>
                        </div>
                    </div>
                </div>
                <div className='cadServicesMainInner servicesReverse row'>
                    <div className='cadServicesInnerRight cadAbout col-6'>

                        <h3><span>3</span>D ANIMATIONS</h3>
                        <p>Take your presentations, portfolios and sales to the next level with the help of our Animation Video Rendering services by showcasing each and every angle of your product allowing your customers to watch that detailing you wanted them to focus on. The use of latest technology and software ensures you get only the best results which are not even achieved by a professional camera. Our videos help you present your products anywhere you like, be it E-Commerce platform, Presentations or YouTube Channels.</p>
                        <p>You can even choose the type of animation video you want like a simple 360° Turntable animation or a Creative animation showing the products from various angles.</p>
                        <div className="cadAboutBtn">
                            <NavLink to="/animation-video">READ MORE</NavLink>
                        </div>
                    </div>
                    <div className='cadServicesInnerLeft col-6'>
                        <div className='cadServicesInnerImage cadServicesInnerImageAfter'>
                            <NavLink to="/animation-video">
                                <video className='videoTag' autoPlay loop muted>
                                    <source src={`${fileUrl}/services-realistic-vid.mp4`} type='video/mp4' />
                                </video>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className='cadServicesMainInner servicesBlack row'>
                    <div className='cadServicesInnerLeft col-6'>
                        <div className='cadServicesInnerImage'>
                            <NavLink to="/cad-modeling">
                                <img src={`${fileUrl}/services-cad-modeling-img.webp`}
                                alt="services-cad-modeling" />
                            </NavLink>
                        </div>
                    </div>
                    <div className='cadServicesInnerRight cadAbout col-6'>
                        <h3><span>3</span>D CAD MODELING</h3>
                        <p>Every piece of jewelry starts as a sparkling idea in your mind or through a lovely piece you saw with a designer or on the internet. With the help of CAD Modeling we can help you turn that idea into reality be it a sketch, a photo or even a design idea in your mind.</p>
                        <p>Our expert CAD Modellers can tell you an estimate cost of your jewelry even before manufacturing it. You can also customize your jewelry any way you want, be it change in the stone shape, the dimensions of the piece, even the type of setting you want, it can all be customized.</p>
                        <p>The CAD file is then further used to 3D Print your product so it can go through the Casting Process. If you wish to render your Jewelry with us a separate CAD file is also created for the purpose free of cost.</p>
                        <div className="cadAboutBtn">
                            <NavLink to="/cad-modeling">READ MORE</NavLink>
                        </div>
                    </div>
                </div>
                <div className='cadServicesMainInner servicesReverse row'>
                    <div className='cadServicesInnerRight cadAbout col-6'>
                        <h3>CAD EDITING</h3>
                        <p>The next step towards bringing your 3D CAD Model to actual reality is getting your CAD Model 3D Printed. With our advanced 3D Printers, you can get your 3D Models printed with the maximum accuracy.</p>
                        <p>You can get your 3D Prints ready for Investment Casting with the Castable Resin or make a Silicon Mold of them with the Blue Resin enabling you to go for multiple castings of your 3D Model.</p>
                        <div className="cadAboutBtn">
                            <NavLink to="/retouching-and-editing">READ MORE</NavLink>
                        </div>
                    </div>
                    <div className='cadServicesInnerLeft col-6'>
                        <div className='cadServicesInnerImage cadServicesInnerImageAfter'>
                            <NavLink to="/retouching-and-editing">
                                <img 
                                src={`${fileUrl}/services-cad-editing-img.webp`}
                                alt="services-cad-editing" />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className='cadContactForm cadAbout'>
                <div className='container'>
                    <h2>CONTACT US</h2>
                </div>
                <div className='row cadContactFormAlign'>
                    <div className='cadContactFormContent cadServicesListContent col-6'>
                        <div className='cadContactFormImg cadServicesInnerImage'>
                            <NavLink to="/contact-us">
                                <img 
                                src={`${fileUrl}/contact-us-form-services.webp`}
                                alt="contact-us-form-services" />
                            </NavLink>
                        </div>
                    </div>
                    <Contactform />
                </div>
            </div>

            <PaymentMethods />

            <Footer />
            </>
        )
}


export default Ourservices;