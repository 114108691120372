import React,{useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';
import HomeBanner from '../components/HomeBanner';
import Contactform from '../components/Contactform';
import SliderHome from '../components/SliderHome';
import TestimonialSlider from '../components/TestimonialSlider';
import ProjectsCompleted from '../components/ProjectsCompleted';
import PaymentMethods from '../components/PaymentMethods';
import CookiesPopup from '../components/CookiesPopup';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';

import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const Home = () => {
      useEffect(() => {
            window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "instant"
              });
          }, []);
        return(
              <>
              <Title title="CAD AND RENDER" />
              <Header />

              <HomeBanner />
              <CookiesPopup />
              <ContactIcon />
              <WhatsappChat />
              <ScrollTop />


              <div className='cadAbout cadAboutHome'>
                  <div className='container'>
                        <div className='cadAboutTitleImg'>
                              <img 
                              src={`${fileUrl}/angle-down.webp`}
                              alt="angle-down" />
                        </div>
                        <h6>ABOUT</h6>
                        <h2>WHO WE ARE?</h2>
                        <p>We at CAD & RENDER are a professional and experienced team of 3D artists who offer you the possibility of growing your business exponentially through the creation of 3D CAD Models, Rendered Photorealistic Images, Rendered Realistic Animation Videos and 3D Model Prints of your jewelry products enabling you to get a boost in your sales through the quality and accuracy of our work.</p>
                        <div className="cadAboutBtn">
                              <NavLink to="/about-us">READ MORE</NavLink>
                        </div>
                  </div>
              </div>


              <div class="cadServices cadAbout">
                  <h2>WHAT WE OFFER?</h2>
                  <div className='cadServicesInner row'>
                        <div className='cadServicesInnerCol col-12'>
                              <div className='cadServicesInnerAlign row'>
                                    <div className='cadServicesImgsec col-6'>
                                          <div className='cadServicesImgSecInner'>
                                          <NavLink to="/realistic-rendering">
                                                <img 
                                                src={`${fileUrl}/services-img-1.webp`}
                                                alt="realistic-service" />
                                          </NavLink>
                                          </div>
                                    </div>
                                    <div className='cadServicesContent col-6'>
                                          <h4>REALISTIC IMAGES</h4>
                                          <p>Showcase your jewelry to the whole world with Rendered Photorealistic Still Images. Customize through the endless possibilities of How you want your Jewelry to look.</p>
                                          <div className="cadAboutBtn">
                                                <NavLink to="/realistic-rendering">READ MORE</NavLink>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className='cadServicesInnerCol col-12'>
                              <div className='cadServicesInnerAlign cadServicesRevers row'>
                                    <div className='cadServicesContent col-6'>
                                          <h4><span>3</span>D ANIMATIONS</h4>
                                          <p>Get yourself a Professional Video of your product which enhances the beauty of your item and allows the viewer to explore all the crucial angles of your masterpiece.</p>
                                          <div className="cadAboutBtn">
                                                <NavLink to="/animation-video">READ MORE</NavLink>
                                          </div>
                                    </div>
                                    <div className='cadServicesImgsec col-6'>
                                          <div className='cadServicesImgSecInner ImgInnerRight'>
                                                {/* <img src='/assets/images/Capture4.JPG' /> */}
                                                <NavLink to="/animation-video">
                                                      <video className='videoTag' autoPlay loop muted>
                                                            <source src={`${fileUrl}/animation-vid-home.mp4`} type='video/mp4' />
                                                      </video>
                                                </NavLink>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className='cadServicesInnerCol col-12'>
                              <div className='cadServicesInnerAlign row'>
                                    <div className='cadServicesImgsec col-6'>
                                          <div className='cadServicesImgSecInner'>
                                          <NavLink to="/cad-modeling">
                                                <img 
                                                src={`${fileUrl}/services-img-3.webp`}
                                                alt="3D-cad-modeling" />
                                          </NavLink>
                                          </div>
                                    </div>
                                    <div className='cadServicesContent col-6'>
                                          <h4><span>3</span>D CAD MODELING</h4>
                                          <p>Turn your ideas into reality with the help of our expert CAD Artists who can design your jewelry just the way you want, be it traditional jewelry, artistic modern designs or 3D organic face and animal figures.</p>
                                          <div className="cadAboutBtn">
                                                <NavLink to="/cad-modeling">READ MORE</NavLink>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className='cadServicesInnerCol col-12'>
                              <div className='cadServicesInnerAlign cadServicesRevers row'>
                                    <div className='cadServicesContent col-6'>
                                          <h4>CAD EDITING</h4>
                                          <p>Have the CAD Models already and want to make some changes or create something new out of it? We also hold the expertise in editing your previously made models, doesn’t matter if the models are made by us or someone else, as long as the models are in editable condition, we can do it very happily.</p>
                                          <div className="cadAboutBtn">
                                                <NavLink to="/retouching-and-editing">READ MORE</NavLink>
                                          </div>
                                    </div>
                                    <div className='cadServicesImgsec col-6'>
                                          <div className='cadServicesImgSecInner ImgInnerRight'>
                                          <NavLink to="/retouching-and-editing">
                                                <img 
                                                src={`${fileUrl}/cad-editing-home.webp`}
                                                alt="CAD-editing" />
                                          </NavLink>
                                          </div>
                                    </div>
                              </div>
                        </div>
                        {/* <div className='cadServicesInnerCol col-12'>
                              <div className='cadServicesInnerAlign row'>
                                    <div className='cadServicesImgsec col-6'>
                                          <div className='cadServicesImgSecInner'>
                                                <img src='/assets/images/image-5.jpg' />
                                          </div>
                                    </div>
                                    <div className='cadServicesContent col-6'>
                                          <h4><span>3</span>D MODEL PRINTS</h4>
                                          <p>Bring your 3D creation alive by 3D Printing your models to either get them casted directly through the Castable Resin or make a silicon mould of it through Blue Resin to make multiple castings.</p>
                                          <div className="cadAboutBtn">
                                                <NavLink to="/cad-modeling">READ MORE</NavLink>
                                          </div>
                                    </div>
                              </div>
                        </div> */}

                        {/* Add new Interactive content section */}
                  </div>
              </div>

              <div className="cadWhyChooseUS cadAbout">
                  <div className='container'>
                        <h2>WHY CHOOSE US?</h2>
                        <p>We are a professional team of expert 3D artists who have years of experience in Jewelry Designing and 3D Animation. We know how to handle your jewelry and present it just like you have imagined. Our expertise and experience in the industry allows us to create any type of design with utmost precision and perfection while retaining the delicacy and beauty of the article that is essential for it to stand out. When it comes to presentation, we leave no stone unturned while setting up the studio for creating your realistic rendered images and videos, be it lightning, environment tweaks or camera positions so you get the best results possible.</p>
                        <p>While creating the 3D Designs and Renders our team always considers all your choices and preferences and upon your wish we also sometimes suggest few edits from our end that might look amazing on your designs. We have clients all over the globe and have worked in almost each and every market allowing us to ascertain the tastes and preferences of the people worldwide and we never ignore even the minutest of details or design aspects so you can be assured that the jewelry we design for you will be free from any sort of technical glitches and will be made perfectly so your manufacturing process goes hassle free. Work with us today and let us handle all your headaches regarding 3D CAD Designing and Rendering needs for a smooth and hassle free experience.</p>
                        <p>Still confused? Order your FREE SAMPLE now and judge yourself how our work is.</p>
                  </div>
              </div>

              <div className='cadGallery cadAbout'>
              <h2>GALLERY</h2>
                  <SliderHome />
                  <div className="cadGalleryBtn cadAboutBtn">
                        <NavLink to="/gallery">VIEW ALL</NavLink>
                  </div>
              </div>

              <div className='cadContactForm cadAbout'>
                <div className='container'>
                    <h2>CONTACT US</h2>
                </div>
                <div className='row cadContactFormAlign'>
                    <div className='cadContactFormContent cadServicesListContent col-6'>
                        <div className='cadContactFormImg cadServicesInnerImage'>
                              <NavLink to="/contact-us">
                                    <img 
                                    src={`${fileUrl}/contact-us-form-home.webp`}
                                    alt="contact-us-form" />
                              </NavLink>
                        </div>
                    </div>
                    <Contactform />
                </div>
            </div>

              <div className='cadTestimonials cadAbout'>
                  <div className='container'>
                        <h2>PEOPLE ABOUT US</h2>
                        <TestimonialSlider />
                  </div>
              </div>

              <ProjectsCompleted />

              <PaymentMethods />

              <Footer />
              </>  
        )
}



export default Home;