import React,{useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import Contactform from '../components/Contactform';
import HeaderSec from '../components/HeaderSec';
import Footer from '../components/Footer';
import PaymentMethods from '../components/PaymentMethods';
import Accordians from '../components/Accordians';
import CookiesPopup from '../components/CookiesPopup';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';
import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const Faq = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
        return(
            <>
            <Title title="FAQ | CAD AND RENDER" />
            <HeaderSec />

            <div className='cadFaqBanner cadAboutBanner cadContactBanner'>
                <img 
                src={`${fileUrl}/faq-banner.webp`}
                alt="fa-banner" />
                <div className='container'>
                    <div className='cadContactBannerContent'>
                        <h1>FAQ</h1>
                    </div>
                </div>
            </div>

            <div className='cadAboutContent'>
                <div className='container'>
                    <p>At CAD and RENDER, we specialize in providing high-quality jewelry 3D CAD Designing and Realistic Rendering services to jewelry manufacturers, wholesalers and retailers. With years of experience in the industry, we have become experts in our craft and are dedicated to providing exceptional service to our clients.</p>
                </div>
            </div>

            <ContactIcon />
            <WhatsappChat />
            <ScrollTop />
            <CookiesPopup />

            <div className='cadFaqAcc'>
                <div className='container'>
                    <Accordians />
                </div>
            </div>
            
            <div className='cadContactForm cadAbout'>
                <div className='container'>
                    <h2>CONTACT US</h2>
                </div>
                <div className='row cadContactFormAlign'>
                    <div className='cadContactFormContent cadServicesListContent col-6'>
                        <div className='cadContactFormImg cadServicesInnerImage'>
                            <NavLink to="/contact-us">
                                <img 
                                src={`${fileUrl}/faq-contact-form.webp`}
                                alt="faq-contact-form" />
                            </NavLink>
                        </div>
                    </div>
                    <Contactform />
                </div>
            </div>


            <PaymentMethods />

            <Footer />
            </>
        )
}


export default Faq;