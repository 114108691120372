import React,{ useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Contactform from '../components/Contactform';
import HeaderSec from '../components/HeaderSec';
import Footer from '../components/Footer';
import PaymentMethods from '../components/PaymentMethods';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';
import CookiesPopup from '../components/CookiesPopup';

import RealisticGallery from '../components/RealisticGallery';
import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const Photorealisticrendering = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
        return(
            <>
            <Title title="Realistic Images | CAD AND RENDER" />
            <HeaderSec />

            <div className='cadRealisticBanner cadAboutBanner cadContactBanner'>
                <img 
                src={`${fileUrl}/realistic-img-banner.webp`}
                alt="realistic-services-banner" />
                <div className='container'>
                    <div className='cadContactBannerContent'>
                        <h1>Realistic Images</h1>
                    </div>
                </div>
            </div>

            <div className='cadPhotoRealisticMain cadAboutContent'>
                <div className='container'>
                    <p>In the 3D designing world, rendering is the process of creating a photorealistic or non-photorealistic image using a 2D or 3D model through a computer software program. The resulting image is called a Render. This image so created is a particular view of the 3D or 2D model used to generate the renders.</p>
                    <p>Rendering is widely used in various digital sectors like architecture, jewelry presentation, video games, animated movies, VFX, SFX, etc. Rendering helps in showcasing your product more beautifully and accurately, and in a cost effective and less time consuming way. The quality and type of these renders depends on their utility, like uploading the renders on your websites and social media for sales, promotion and other benefits, or using them for presentations in your office, etc. The uses of renders are varied be it realistic or non-realistic, both have their separate meaning and importance and one cannot replace the need for the other.</p>
                </div>
            </div>

            <div className='cadRealisticGallery cadAbout'>
                <div className='container'>
                    <h2>REALISTIC GALLERY </h2>
                    <RealisticGallery />
                </div>
            </div>
                
            <ContactIcon />
            <WhatsappChat />
            <ScrollTop />
            <CookiesPopup />

            <div className='cadContactForm cadAbout'>
                <div className='container'>
                    <h2>CONTACT US</h2>
                </div>
                <div className='row cadContactFormAlign'>
                    <div className='cadContactFormContent cadServicesListContent col-6'>
                        <div className='cadContactFormImg cadServicesInnerImage'>
                            <NavLink to="/contact-us">
                                <img 
                                src={`${fileUrl}/realistic-contact-form.webp`}
                                alt="realistic-contact-form" />
                            </NavLink>    
                        </div>
                    </div>
                    <Contactform />
                </div>
            </div>

            <div className='cadPhotoRealistic cadAbout'>
                <div className='container'>
                    <h2>REALISTIC JEWELRY RENDERS </h2>
                    <p>In the 3D designing world, rendering is the process of creating a photorealistic or non-photorealistic image using a 2D or 3D model through a computer software program. The resulting image is called a Render. This image so created is a particular view of the 3D or 2D model used to generate the renders.</p>
                    <p>Rendering is widely used in various digital sectors like architecture, jewelry presentation, video games, animated movies, VFX, SFX, etc. Rendering helps in showcasing your product more beautifully and accurately, and in a cost effective and less time consuming way. The quality and type of these renders depends on their utility, like uploading the renders on your websites and social media for sales, promotion and other benefits, or using them for presentations in your office, etc. The uses of renders are varied be it realistic or non-realistic, both have their separate meaning and importance and one cannot replace the need for the other.</p>   
                    <p>After getting your 3D Jewelry model ready, the next step is to showcase your masterpiece to the whole world. Your client wants to see your products just as they would appear in real life, the color and shade of the gemstones, the gleam of the metal, the sparkle in the diamonds, everything should be perfect, and what better way to achieve this quality other than getting it rendered. Whether you’re a worldwide renowned entity with a huge catalogue or a small scale entrepreneur with select masterpieces, 3D Photorealistic Image Rendering will heighten your products’ appearance significantly helping you in accomplishing your goals much faster, be it presentations, customized proposals or e-commerce sales.</p>
                    <p>When it comes to Jewelry presentation, the renders we create for you are just realistic. We always keep in mind that the renders should appear as if a live product’s image is being showed to them. The use of latest technology and software programs ensures you get only the best results which are not even achieved by a professional camera. We take all the factors responsible for creating the perfect render into consideration like lightning, virtual scene setups, suitable environments, backdrops and backgrounds, camera positions, etc.</p>
                    <p>Apart from these benefits, the world of rendering opens many other opportunities in front of you. As you know these rendered images are not real, they are created using the 3D Models which are developed for manufacturing purposes, you can create an endless number of variations to showcase your item. You can make combinations from different metal colors, gemstone types, viewing angles, innovative backgrounds, etc. the limit is your imagination. Our professional team considers all your choices and preferences to give the perfect look for your item. Another major benefit of jewelry rendering is the ability to create an endless inventory of your choice of products of any type. As the images and videos created are very realistic, there is no need to opt for photography or videography as an even better result is already created for those purposes using the 3D model which you will use for manufacturing your item in the future.</p>
                    
                </div> 
            </div>

            <PaymentMethods />

            <Footer />
            </>
        )
}


export default Photorealisticrendering;