import React,{useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import HeaderSec from '../components/HeaderSec';
import Footer from '../components/Footer';
import CookiesPopup from '../components/CookiesPopup';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';
import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const NotFound = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
    return(
        <>
        <Title title="Not Found | CAD AND RENDER" />
        <HeaderSec />

        <div className='cadPNFBanner cadAboutBanner cadContactBanner'>
            <img 
            src={`${fileUrl}/not-found-banner.webp`}
            alt="not-found-banner" />
            <div className='container'>
                <div className='cadPNFBannerContent cadContactBannerContent'>
                    <h1>Not Found</h1>
                </div>
            </div>
        </div>

        <ContactIcon />
        <WhatsappChat />
        <ScrollTop />
        <CookiesPopup />

        <div className='cadPNFContent cadAbout'>
            <div className='container'>
                <h2><span>404</span></h2>
                <h3>Page Not Found</h3>
                <div className="cadPNFBtn cadAboutBtn">
                    <NavLink to="/">BACK TO HOME</NavLink>
                </div>
            </div>
        </div>

        <Footer />
        </>
    );
}


export default NotFound;