import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/effect-coverflow";
import 'swiper/css/autoplay';

const TestimonialSlider = () => {

    const [testimonials, setTestimonials] = useState([]);

    useEffect(()=>{
        axios.get("https://www.cadandrender.com/adminPanel/API/testimonials.php").then((response)=>{
          setTestimonials(response.data.Testimonials);
      })
    },[]);

    SwiperCore.use([Autoplay]);

    return (
      <>
            <Swiper
            effect={"coverflow"}
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            // autoplay={{
            //     delay: 10000,
            //     pauseOnMouseEnter: true,
            //     disableOnInteraction: false
            //    }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              modifier: 1,
              slideShadows: false,
            }}
                modules={[Autoplay,EffectCoverflow,Navigation]}
                navigation
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                >

            {
            testimonials.map ((tst,index)=>{
                return(
                    <>
                    <SwiperSlide>
                        <div className="row cadTestimonialAlign" key={index}>
                            <div className="cadTestimonialImg">
                                <img 
                                src={`${tst.UsersMedia_MediaUrl}`}
                                alt="cadandrender-testimonial" />
                            </div>
                            <div className="cadTestimonialInner cadServicesListContent">
                                <div className="clientReviews">
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                </div>
                                <p>{tst.Testimonials_UserMessage}</p>
                                <p><span>{tst.Testimonials_UserCountry}</span></p>
                                <p className="cadTestimonialTitle">{tst.Testimonials_UserName}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                    </>
                    )
                })
            }
            </Swiper>
      </>
    );
}

export default TestimonialSlider;