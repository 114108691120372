import React from 'react';
import ReactDOM from 'react-dom/client';

import './css/font-awesome.min.css';
import './css/style.css'; 
import './css/responsive.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
// const cors = require('cors');
// App.use(cors({
//   origin: 'https://localhost:3000',
//   methods: ['POST'],  
// }));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
