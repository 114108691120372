import React, { useEffect, useState } from 'react';

function ImageModal({ images, selectedIndex, onClose }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(selectedIndex);

  const prevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const nextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const handleOutsideClick = (event) => {
      if (event.target.classList.contains('modal-backdrop')) {
        onClose();
      }
    };

    document.body.addEventListener('click', handleOutsideClick);

    return () => {
      document.body.style.overflow = 'auto';

      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, [onClose]);

  useEffect(() => {
    setCurrentImageIndex(selectedIndex);
  }, [selectedIndex]);

  return (
    <>
      <div className="modal-backdrop"></div>

      <div className="modal">
        <div className="modal-content">
          <span className="close" onClick={onClose}>&times;</span>
          <img 
          src={`${images[currentImageIndex].UsersMedia_MediaUrl}`}
          alt="gallery-popup" />
          <div className="nav-buttons">
            <button className='btnLeft' onClick={prevImage} disabled={currentImageIndex === 0}>
              <i className="fa fa-chevron-left"></i>
            </button>
            <button className='btnRight'
              onClick={nextImage}
              disabled={currentImageIndex === images.length - 1}
            >
              <i className="fa fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageModal;