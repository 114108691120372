import React,{useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import Contactform from '../components/Contactform';
import HeaderSec from '../components/HeaderSec';
import Footer from '../components/Footer';
import PaymentMethods from '../components/PaymentMethods';
import CookiesPopup from '../components/CookiesPopup';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';

import CadModelingEditingGallery from '../components/CadModelingEditingGallery';

import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const CadModeling = () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant"
      });
      }, []);
        return(
            <>
            <Title title="3D CAD Modeling | CAD AND RENDER" />
            <HeaderSec />

            <div className='cadModelingMain cadAboutBanner cadContactBanner'>
                <img 
                src={`${fileUrl}/cad-modeling-banner.webp`}
                alt="cad-modeling-banner" />
                <div className='container'>
                    <div className='cadContactBannerContent'>
                        <h1><span>3</span>D CAD Modeling</h1>
                    </div>
                </div>
            </div>

            <div className='cadPhotoRealisticMain cadAboutContent'>
                <div className='container'>
                    <p>In the 3D designing world, rendering is the process of creating a photorealistic or non-photorealistic image using a 2D or 3D model through a computer software program. The resulting image is called a Render. This image so created is a particular view of the 3D or 2D model used to generate the renders.</p>
                    <p>Rendering is widely used in various digital sectors like architecture, jewelry presentation, video games, animated movies, VFX, SFX, etc. Rendering helps in showcasing your product more beautifully and accurately, and in a cost effective and less time consuming way. The quality and type of these renders depends on their utility, like uploading the renders on your websites and social media for sales, promotion and other benefits, or using them for presentations in your office, etc. The uses of renders are varied be it realistic or non-realistic, both have their separate meaning and importance and one cannot replace the need for the other.</p>
                </div>
            </div>

            <div className='cadRealisticGallery cadAbout'>
                <div className='container'>
                    <h2>CAD DESIGNING GALLERY </h2>
                    <CadModelingEditingGallery />
                </div>
            </div>

            <ContactIcon />
            <WhatsappChat />
            <ScrollTop />
            <CookiesPopup />
            
            <div className='cadContactForm cadAbout'>
                <div className='container'>
                    <h2>CONTACT US</h2>
                </div>
                <div className='row cadContactFormAlign'>
                    <div className='cadContactFormContent cadServicesListContent col-6'>
                        <div className='cadContactFormImg cadServicesInnerImage'>
                            <NavLink to="/contact-us">
                                <img 
                                src={`${fileUrl}/cad-modeling-contact-form.webp`} 
                                alt="cad-modeling-contact-form"/>
                            </NavLink>   
                        </div>
                    </div>
                    <Contactform />
                </div>
            </div>

            <div className='cadPhotoRealistic cadAbout'>
                <div className='container'>
                    <h2>JEWELRY CAD DESIGNING</h2>
                    
                    <p>CAD or Computer Aided Design means creating 3D models of any product using different computer software programs. CAD Designing over comes the need of basic traditional hand designing as designing through computer is very accurate and uniform, eliminating the imperfections of designs made by hand. Moreover, you can also bring your creativity in the original design, so that the model is made exactly the way you want it to be. </p>
                    <p>In today’s world, traditional handcrafting procedures of jewelry manufacturing are being taken over by modern processes like 3D CAD Designing, 3D Printing, Investment Casting, etc. as the designs made are more accurate and manufacturing through these methods is a very fast process as compared to the traditional techniques.</p>
                    <p>When it comes to Jewelry 3D CAD Designing, all the measurements and even the tiniest of details are taken into account so that the items are developed with uniformity and utmost precision. What’s even better is that you can boost your creativity and create various new and innovative versions of the original design which totally depends on your imagination and desire, our designers are skilled enough to meet out all your requirements and design any type of jewelry you want. We will turn your inspiration to an actual design, for you to share your creation with the whole world. Through 3D CAD Modeling you can see what your design will look like in the real world so you can spot and correct any flaws or changes in the design before you move to 3D Prototyping. </p>
                    <p>The world of CAD Modeling only limits on your imagination and the practicality of the design. We can create any type of jewelry you desire, be it Rings, Earrings, Necklaces, Pendants, Bracelets, Bangles, Cufflinks and all other accessories. We can also create expressive, artistic and unique 3D Figures like – Animals, Humans, Mythological creatures, Unreal creations and many others.</p>
                    <p>The process of converting boring 2D designs to lively 3D models start from just a photo, a sketch or even just an idea. After finalizing your choice of design, we will be needing all the dimensions and details of the design to be made. It includes the size of the item, the sizes of the stones that will be set in the design, the type of setting for the stones, weight requirements (if any), any alteration in the original design. You can go for amazing edits in your design like applying textures on the design (or just a part of the design), have your logo, name or any other design engraved or embossed, change the center stone of the original design, etc. After you provide with all the details, our professional designers will design your jewelry according to your requirements and when the design is made, we will send you a draft image so you can view the design and tell us if it needs any change or is it finalized. </p>
                    <p>After the design is finalized, it is sent in the form of digital files to 3D Printers where they are 3D printed and you get your live physical prototype of the jewelry you just got designed, which is ready for mold making or investment casting process.</p>
                    
                </div>
            </div>

            <PaymentMethods />

            <Footer />
            </>
        )
}


export default CadModeling;