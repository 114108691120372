import React from 'react';
import { Link } from 'react-router-dom';

const fileUrl = "https://www.cadandrender.com/files";

const PaymentMethods = () => {
    return(
        <>
        <div className='cadPaymentMethods cadAbout'>
            <div className='container'>
                <h2>PAYMENT OPTIONS</h2>
                <p>How would you like to pay?</p>
                <div className='row cadPaymentAlign cadPaymentFull'>
                    <div className='cadPaymentOptions col-3'>
                        {/* <a href="#"> */}
                            <img 
                            src={`${fileUrl}/paypal.png`}
                            alt="paypal" />
                        {/* </a> */}
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        {/* <a href="#"> */}
                            <img 
                            src={`${fileUrl}/razorpay.png`}
                            alt="razorpay"
                             />
                        {/* </a> */}
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        {/* <a href="#"> */}
                            <img 
                            src={`${fileUrl}/visa.png`}
                            alt="visa"
                             />
                        {/* </a> */}
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        {/* <a href="#"> */}
                            <img 
                            src={`${fileUrl}/mastercard.png`}
                            alt="mastercard"
                             />
                        {/* </a> */}
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        <Link to="/phone-pay" target='_blank'>
                            <img 
                            src={`${fileUrl}/phone-pay.png`}
                            alt="phone-pay"
                             />
                        </Link>
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        <Link to="/paytm" target="_blank">
                            <img 
                            src={`${fileUrl}/paytm.png`}
                            alt="paytm"
                             />
                        </Link>
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        <Link to="/google-pay" target="_blank">
                            <img 
                            src={`${fileUrl}/g-pay.png`}
                            alt="google-pay"
                             />
                        </Link>
                    </div>
                </div>
                <div className='row cadPaymentMobile'>
                <div className='cadPaymentOptions col-3'>
                        {/* <a href="#"> */}
                            <img 
                            src={`${fileUrl}/paypal.png`}
                            alt="paypal" />
                        {/* </a> */}
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        {/* <a href="#"> */}
                            <img 
                            src={`${fileUrl}/razorpay.png`}
                            alt="razorpay" />
                        {/* </a> */}
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        {/* <a href="#"> */}
                            <img 
                            src={`${fileUrl}/visa.png`}
                            alt="visa" />
                        {/* </a> */}
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        {/* <a href="#"> */}
                            <img 
                            src={`${fileUrl}/mastercard.png`}
                            alt="mastercard" />
                        {/* </a> */}
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        <a href="upi://pay?pa=9587704344@ybl&pn=KunalRewadia&cu=INR">
                            <img 
                            src={`${fileUrl}/phone-pay.png`}
                            alt="phone-pay" />
                        </a>
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        <a href="upi://pay?pa=9587704344@ybl&pn=KunalRewadia&cu=INR">
                            <img 
                            src={`${fileUrl}/paytm.png`}
                            alt="paytm" />
                        </a>
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        <a href="upi://pay?pa=9587704344@ybl&pn=KunalRewadia&cu=INR">
                            <img 
                            src={`${fileUrl}/g-pay.png`}
                            alt="google-pay" />
                        </a>
                    </div>

                    <div className='cadPaymentOptions col-3'>
                        <a href="upi://pay?pa=9587704344@ybl&pn=KunalRewadia&cu=INR">
                            <img 
                            src={`${fileUrl}/upi.png`}
                            alt="upi" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default PaymentMethods;