import React, {useState, useEffect} from 'react';

const fileUrl = "https://www.cadandrender.com/files";

const ScrollTop = () => {

    const [showButton, setShowButton] = useState(false);

  function handleScroll() {
    if (window.pageYOffset > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }

  function handleClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
    return(
       
        showButton && (
      <div className="ScrollTopBtn">
        <button className="scroll-to-top-button" onClick={handleClick}>
          <img 
          src={`${fileUrl}/scroll-top-img.png`}
          alt="scroll-top-icon" />
        </button>
      </div>
    )
        
    );
}

export default ScrollTop;