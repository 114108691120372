import React,{useEffect} from 'react';
import Contactform from '../components/Contactform';
import HeaderSec from '../components/HeaderSec';
import Footer from '../components/Footer';
import PaymentMethods from '../components/PaymentMethods';
import CookiesPopup from '../components/CookiesPopup';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';
import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const Contact = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
        return(
            <>
            <Title title="Contact | CAD AND RENDER" />
            <HeaderSec />

            <div className='cadContactBanner cadAboutBanner'>
                <img 
                src={`${fileUrl}/contact-us-banner.webp`}
                alt="contact-us-banner" />
                <div className='container'>
                    <div className='cadContactBannerContent'>
                        <h1>Contact</h1>
                    </div>
                </div>
            </div>

            <div className='cadContactMaincontent cadGallerycontent cadAboutContent'>
                <div className='container'>
                    <p>At CAD and RENDER, we specialize in providing high-quality jewelry 3D CAD Designing and Realistic Rendering services to jewelry manufacturers, wholesalers and retailers. With years of experience in the industry, we have become experts in our craft and are dedicated to providing exceptional service to our clients.</p>
                </div>
            </div>

            <ContactIcon />
            <WhatsappChat />
            <ScrollTop />
            <CookiesPopup />

            <div className='cadContactForm cadAbout'>
                <div className='container'>
                    <h2>CONTACT US</h2>
                </div>
                <div className='row cadContactFormAlign'>
                    <div className='cadContactFormContent cadServicesListContent col-6'>
                        <div className='cadContactFormImg cadServicesInnerImage'>
                            <img 
                            src={`${fileUrl}/contact-us-form-img.webp`}
                            alt="contact-us-form" />
                        </div>
                    </div>
                    <Contactform />
                </div>
            </div>

            <div className='cadContactMap'>
                <iframe 
                title='cad-map'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8460.318257739113!2d75.808084286857!3d26.931230916553403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db1cb9953f405%3A0xcf8bcae9e2acbfe2!2sCad%20%26%20Render!5e0!3m2!1sen!2sin!4v1679936038588!5m2!1sen!2sin"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <PaymentMethods />
            

            <Footer />
            </>
        )
}


export default Contact;