import React from 'react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const fileUrl = "https://www.cadandrender.com/files";

const SliderHome = () => {

  var settings = {
    dots: false,
    className: 'center',
    centerMode: true,
    centerPadding: '0',
    infinite: true,
    arrows: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2500,
    slidesToShow: 3,
    slidesToScroll: 1,
    InitalSlide : 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
          arrows: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true
        }
      }
    ]
  };

    return (
      <>
      <div className="cadSwiperSlider">
        <Slider {...settings}>
              <div>
                <img 
                src={`${fileUrl}/gallery-img-7.webp`}
                alt="cadandrender-gallery" />
              </div>
              <div>
                <img 
                src={`${fileUrl}/gallery-img-9.webp`}
                alt="cadandrender-gallery" />
              </div>
              <div>
                <img 
                src={`${fileUrl}/gallery-img-11.webp`}
                alt="cadandrender-gallery" />
              </div>
              <div>
                <img 
                src={`${fileUrl}/gallery-img-12.webp`}
                alt="cadandrender-gallery" />
              </div>
              <div>
                <img 
                src={`${fileUrl}/gallery-img-18.webp`}
                alt="cadandrender-gallery" />
              </div>
              <div>
                <img 
                src={`${fileUrl}/gallery-img-22.webp`}
                alt="cadandrender-gallery" />
              </div>
              <div>
                <img 
                src={`${fileUrl}/gallery-img-36.webp`}
                alt="cadandrender-gallery" />
              </div>
              <div>
                <img 
                src={`${fileUrl}/gallery-img-24.webp`}
                alt="cadandrender-gallery" />
              </div>
        </Slider>
      </div>
      </>
    );
}

export default SliderHome;