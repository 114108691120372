import React, { useState, useEffect } from 'react';
import axios from 'axios';

import ImageModal from './ImageModal';

function RealisticGallery() {
  const [noOfElements, setNoOfElement] = useState(20);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const loadMore = () => {
    setNoOfElement(noOfElements + 20);
  };

  const openImageModal = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImageModal = () => {
    setSelectedImageIndex(null);
  };

  const[galleryImages,setGalleryImages]= useState({ Gallery: [] });
    
  useEffect(()=>{
    axios.get("https://www.cadandrender.com/adminPanel/API/image_gallery.php").then((response)=>{
      const filteredImages = response.data.Gallery.filter(image => image.ImageGallery_Category === "Realistic Images");
      setGalleryImages({ Gallery: filteredImages });
  })
  },[]);

  return (
    <>
      <div className="tab-content">
        <div className="tabcontent1">
          <div className="tabcontent1GalleryInner clearfix">
            {galleryImages.Gallery.slice(0, noOfElements).map((res, index) => {
              return (
                <div className="tabcontentGallery col-3" key={index}>
                  <div className="tabcontentGalleryInner">
                    <img
                      src={`${res.UsersMedia_MediaUrl}`}
                      alt="realistic-gallery"
                      onClick={() => openImageModal(index)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <button
            style={{
              display:
                noOfElements >= galleryImages.Gallery.length
                  ? 'none'
                  : 'block',
            }}
            className="loadmoreGalleryBtn"
            onClick={() => loadMore()}
          >
            LOAD MORE
          </button>
        </div>
      </div>
      {selectedImageIndex !== null && (
        <ImageModal
          images={galleryImages.Gallery}
          selectedIndex={selectedImageIndex}
          onClose={closeImageModal}
        />
      )}
    </>
  );
}

export default RealisticGallery;