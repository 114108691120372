import React, {useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import SocialMedia from './SocialMedia';

const fileUrl = "https://www.cadandrender.com/files";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const [confooter, setConfooter] = useState([]);

    useEffect(()=>{
        axios.get("https://www.cadandrender.com/adminPanel/API/contact.php").then((response)=>{
            setConfooter(response.data.Contact);
      })
    },[]);

        return(
            <>
            <div className='cadFooterMain'>
                <div className='cadFooterTop'>
                    <div className='container clearfix'>
                        <div className='cadFooterOne col-3'>
                            <div className='cadFooterLogo'>
                                <NavLink to="/">
                                    <img 
                                    src={`${fileUrl}/logo.png`}
                                    alt="cadandrender-logo" />
                                </NavLink>
                            </div>
                            <p>TURN IDEAS TO REALITY</p>
                            <div className='cadFooterSocials cadContactSocials'>
                                <SocialMedia />
                            </div>
                        </div>
                        <div className='cadFooterOne cadFooterNavigation col-4'>
                            <div className='cadFooterOneInner'>
                                <h4>NAVIGATION</h4>
                                <ul className='cadFooterNav'>
                                    <li><NavLink to="/" >Home</NavLink></li>
                                    <li><NavLink to="/about-us" >About</NavLink></li>
                                    <li><NavLink to="/our-services" >Services</NavLink></li>
                                    {/* <li><NavLink to="/blogs" >Blogs</NavLink></li> */}
                                    <li><NavLink to="/contact-us" >Contact</NavLink></li>
                                    <li><NavLink to="/faq" >FAQ</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className='cadFooterOne cadFooterContact col-5'>
                            <div className="cadFooterContactInner">                            
                            <h4>CONTACT US</h4>
                                <div className='cadFooterContact cadContactDetails'>
                                    <ul>
                                    {confooter.map((res)=>
                                    <>
                                        <li><i className="fa fa-phone"></i><NavLink to={`tel: ${res.CadContact_ContactNumber}`} target="_blank">{res.CadContact_ContactNumber}</NavLink></li>
                                        <li><i className="fa fa-envelope-o"></i><NavLink to={`mailo: ${res.CadContact_Email}`} target="_blank">{res.CadContact_Email}</NavLink></li>
                                        <li><i className='fa fa-map-marker'></i><NavLink to={`${res.CadContact_LocationUrl}`} target="_blank">{res.CadContact_Address}</NavLink></li>
                                        <li><span>Monday - Saturday | 10:00am - 06:00pm</span></li>
                                    </>
                                    )}
                                    </ul>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='cadFooterBtm'>
                    <div className='container clearfix'>
                        <p>© {currentYear} Cad & Render by Rewadia Group, All Rights Reserved. <NavLink to="/terms&conditions">Terms & Conditions</NavLink></p>
                    </div>
                </div>
            </div>
            
            </>
        )
} 


export default Footer;