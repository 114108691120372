import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SocialMedia from './SocialMedia';

const fileUrl = "https://www.cadandrender.com/files";

const Toolbarfull = () =>{
    const [sidebar, setSidebar] = useState(false);
    const sidebarRef = useRef(null);

    const handleMenuClick = () => {
        setSidebar(!sidebar);
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setSidebar(false);
        }
    };

    const [conSidebar, setConSidebar] = useState([]);

    useEffect(()=>{
        axios.get("https://www.cadandrender.com/adminPanel/API/contact.php").then((response)=>{
            setConSidebar(response.data.Contact);
        })
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    },[]);

    return(
        <>
        <div className='cadHeaderMenubar' onClick={handleMenuClick}>
            <span className="bars">
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className='bar3'></div>
            </span>
        </div>
        <div ref={sidebarRef} className={sidebar ? "cadHeadaerSidebarOpen cadHeaderSidebar": "cadHeaderSidebar"}>
            <div className='cadMenuClose'>
                <span className='closebtn' onClick={() => setSidebar(!sidebar)}>&#x2715;</span>
            </div>
            <div className='cadHeaderSidebarMain'>
                <div className='cadHeaderSidebarMain-logo'>
                    <img 
                    src={`${fileUrl}/render-logo-black.png`}
                    alt="cadandrender-logo" />
                </div>
                <ul className='cadHeaderSidebarList'>
                {conSidebar.map((res)=>
                <>
                    <li><a href={`tel:${res.CadContact_ContactNumber}`} rel='noreferrer'>{res.CadContact_ContactNumber}</a></li>
                    <li><p>Mon - Sat, 10AM - 6PM</p></li>
                    <li><a href={`mailto:${res.CadContact_Email}`} rel='noreferrer'>{res.CadContact_Email}</a></li>
                    <li><a href={res.CadContact_LocationUrl} rel='noreferrer'>{res.CadContact_Address}</a></li>
                </>
                )}
                </ul>
                <SocialMedia />
            </div>
        </div>
        </>
    );
}

export default Toolbarfull;