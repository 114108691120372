import React,{useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import Contactform from '../components/Contactform';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PaymentMethods from '../components/PaymentMethods';
import CookiesPopup from '../components/CookiesPopup';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';

import GalleryVideos from '../components/GalleryVideos';
import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const Animationvideo = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
        return(
            <>
            <Title title="3D Animations | CAD AND RENDER" />
            <Header />

            <div className='cadServicesAnimationBanner cadModelingMain cadAboutBanner cadContactBanner'>
                <video className='videoTag' autoPlay loop muted>
                    <source src={`${fileUrl}/animations-video.mp4`} type='video/mp4' />
                </video>
                <div className='container'>
                    <div className='cadContactBannerContent'>
                        <h1><span>3</span>D Animations</h1>
                    </div>
                </div>
            </div>

            <div className='cadPhotoRealisticMain cadAboutContent'>
                <div className='container'>
                    <p>In the 3D designing world, rendering is the process of creating a photorealistic or non-photorealistic image using a 2D or 3D model through a computer software program. The resulting image is called a Render. This image so created is a particular view of the 3D or 2D model used to generate the renders.</p>
                    <p>Rendering is widely used in various digital sectors like architecture, jewelry presentation, video games, animated movies, VFX, SFX, etc. Rendering helps in showcasing your product more beautifully and accurately, and in a cost effective and less time consuming way. The quality and type of these renders depends on their utility, like uploading the renders on your websites and social media for sales, promotion and other benefits, or using them for presentations in your office, etc. The uses of renders are varied be it realistic or non-realistic, both have their separate meaning and importance and one cannot replace the need for the other.</p>
                </div>
            </div>

            <div className='cadRealisticGallery cadAbout'>
                <div className='container'>
                    <h2>3D ANIMATIONS GALLERY </h2>
                    <GalleryVideos />
                </div>
            </div>

            <ContactIcon />
            <WhatsappChat />
            <ScrollTop />
            <CookiesPopup />
            
            <div className='cadContactForm cadAbout'>
                <div className='container'>
                    <h2>CONTACT US</h2>
                </div>
                <div className='row cadContactFormAlign'>
                    <div className='cadContactFormContent cadServicesListContent col-6'>
                        <div className='cadContactFormImg cadServicesInnerImage'>
                            <NavLink to="/contact-us">
                                <img 
                                src={`${fileUrl}/animations-contact-form.webp`}
                                alt="animation-contact-form" />
                            </NavLink>
                        </div>
                    </div>
                    <Contactform />
                </div>
            </div>

            <div className='cadPhotoRealistic cadAbout'>
                <div className='container'>
                    <h2>WHAT ANIMATION IS?</h2>
                    <p>The birth of animation is older than the movies, before the concept of photography and videography was introduced, animators used to create animations using various sketches or paintings of cartoons on paper in succession so when they were viewed one after the other, the objects would appear to be moving. Animation is an art of making still objects appear to move. It’s not necessary for the object to be real to create an animation, it simply is a smooth motion appearance of drawn, painted or computer generated objects. Today many animations are made through computers and they can be very detailed and precise like 3D animations or they can appear like the traditional styles through 2D animations. The use of animation in today’s world is very diverse, many prominent sectors like advertisement and marketing, gaming, AR and VR, architecture, entertainment and media, product visualization cannot thrive without computer generated Animation.</p>
                    <p>After you get your jewelry designed and rendered, it’s time to go for the option which presents your item’s uniqueness and beauty just the way you want – 3D Animation.</p>
                    <p>Take your presentations, portfolios and sales to the next level with the help of our 3D Animation Video rendering services. You can showcase each and every angle of your jewelry so your customers can focus on all the crucial highlights and detailing you wanted to show off. Be it a simple 360° Turntable animation or something different like slow-mo, zoom in-out or other creative and innovative animation, we put all our effort and knowledge in delivering you the perfection you need. The use of latest technology and software programs ensure that you get only the best results which are not even achieved by a professional camera. </p>
                    
                </div>
            </div>

            <PaymentMethods />

            <Footer />
            
            </>
            
        )
}


export default Animationvideo;