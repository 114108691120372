import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookiesPopup = () => {
  const [cadrender, setCadrender] = useState(Cookies.get('Cadrender'));
  const [closeCookies, setCloseCookies] = useState(true);

  useEffect(() => {
    if (cadrender) {
      setCloseCookies(false);
    }
  }, [cadrender]);

  function cookiesCloser() {
    setCloseCookies(false);
  }

  function handleAccept() {
    Cookies.set('Cadrender', true, { expires: 7 });
    setCadrender(true);
  }

  if (!cadrender && closeCookies) {
    return (
      <div className="cookies-popup" style={{display:'none'}}>
        <div className='cookiesClosebtn'>
          <button onClick={cookiesCloser} className='cookiesCloseBtn'>&#x2715;</button>
        </div>
        <p className='p'>We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.</p>
        <button onClick={handleAccept}>Accept</button>
      </div>
    );
  }

  return null;
}

export default CookiesPopup;