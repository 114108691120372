import React, {useState, useEffect} from 'react';
import axios from 'axios';

const SocialMedia = () =>{

    const [socialMedia, setSocialMedia] = useState([]);

    useEffect(()=>{
        axios.get("https://www.cadandrender.com/adminPanel/API/social_media.php").then((response)=>{
            setSocialMedia(response.data.SocialMedia);
      })
    },[]);

    return(
        <>
        <ul className='cadHeaderSidebarSocialMedia'>
        {
            socialMedia.map ((scl,index)=>{
                return(
                    <>
                        <li key={index}>
                            <a href={scl.SocialMedia_SocialMediaUrl} target="_blank" rel='noreferrer'>
                                <i className={scl.SocialMedia_SocialIcon}></i>
                            </a>
                        </li>
                    </>
                )
            })
        }
        </ul>
        </>
    )
}

export default SocialMedia;