
import Home from './pages/Home';
import About from './pages/About';
import Ourservices from './pages/Ourservices';
import Photorealisticrendering from './pages/Photorealisticrendering';
import Animationvideo from './pages/Animationvideo';
import CadModeling from './pages/CadModeling';
import ReTouchingEditing from './pages/ReTouchingEditing';
// import Blogs from './pages/Blogs';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import TermsConditions from './pages/TermsConditions';
import NotFound from './pages/NotFound';

import PhonePay from './pages/PhonePay';
import Paytm from './pages/Paytm';
import GooglePay from './pages/GooglePay';

import {BrowserRouter as Router ,Route,Routes} from 'react-router-dom';

const App = () => {
  return (
    <div>
    <Router>
      <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/about-us" element={<About />} exact />
          <Route path="/our-services" element={<Ourservices />} exact />
          <Route path="/realistic-rendering" element={<Photorealisticrendering />} exact />
          <Route path="/animation-video" element={<Animationvideo />} exact />
          <Route path="/cad-modeling" element={<CadModeling />} exact />
          <Route path="/retouching-and-editing" element={<ReTouchingEditing />} exact />
          {/* <Route path="/blogs" element={<Blogs />} exact /> */}
          <Route path="/gallery" element={<Gallery />} exact />
          <Route path="/contact-us" element={<Contact />} exact />
          <Route path="/faq" element={<Faq />} exact />
          <Route path="/terms&conditions" element={<TermsConditions />} exact />
          <Route path="/*" element={<NotFound />} exact />

          <Route path="/phone-pay" element={<PhonePay />} exact />
          <Route path="/paytm" element={<Paytm />} exact />
          <Route path="/google-pay" element={<GooglePay />} exact />
      </Routes>
    </Router>
    </div>
  );
}

export default App;