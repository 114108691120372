import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import HeaderSec from '../components/HeaderSec';
import Footer from '../components/Footer';
import Contactform from '../components/Contactform';
import PaymentMethods from '../components/PaymentMethods';
import CookiesPopup from '../components/CookiesPopup';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';
import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const TermsConditions = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
    return(
        <>
        <Title title="Terms&Conditions | CAD AND RENDER" />
        <HeaderSec />
        <div className='cadTerms-conditionBanner cadModelingMain cadAboutBanner cadContactBanner'>
            <img 
            src={`${fileUrl}/terms-conditions-banner.webp`}
            alt="t&C-banner" />
            <div className='container'>
                <div className='cadContactBannerContent'>
                    <h1>Terms <span>&</span> Conditions</h1>
                </div>
            </div>
        </div>

        <div className='cadAboutContent'>
            <div className='container'>
                <p>Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the <a href="https://www.cadandrender.com/" target='_blank' rel='noreferrer'>www.cadandrender.com</a> website (the "Service") operated by CAD AND RENDER ("us", "we", or "our"). Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms, Terms and Conditions. These Terms, Terms and Conditions apply to all visitors, users and others who access or use the Service.</p>
            </div>
        </div>

        <ContactIcon />
        <WhatsappChat />
        <ScrollTop />
        <CookiesPopup />

        <div className='cadTermsConditionsPolicy'>
            <div className='container'>
                <h4 className='cadTemsAccessHeading'>By accessing or using the Service you agree to be bound by these Terms, Terms and Conditions. If you disagree with any part of the terms then you may not access the Service.</h4>
                <h4 className='cadTermsOrderProcess cadTemsAccessHeading'>Order Process &#8608;</h4>
                <ol>
                    <li>The first step is sending a photo or a draft with the information regarding the product and services you are interested in, for quotation.</li>
                    <li>Then we review everything and quote you the price and turnaround time according to the work.</li>
                    <li>After your confirmation you send a minimum 50% pre-payment, then we move on with your order.</li>
                    <li>If we model, we send you screenshots after the modelling is done; if we render, we’ll consider all your choices and preferences into consideration while rendering; if we print the 3D model we send you photos of the CAM piece and if all is right you proceed with the final payment.</li>
                    <li>After we receive the final payment, we send you the complete project files and/or 3D model CAM prints (.3dm, .stl files and a detailed info file if required) depending on the services you opt for.</li>
                </ol>
                <h4 className='cadTermsPaymentHeading cadTemsAccessHeading'>Payment</h4>
                <p className='cadTermsPaymentPara'>You may pay through PayPal, Payoneer, Wire Transfer, Debit and Credit Cards (MasterCard, Visa, Diners Club, American Express, Discover, RuPay) or UPI (GooglePay, PayTm, PhonePe), we accept payment through all of them.</p>
                <p className='cadTermsPaymentPara'>The client shall ensure the amount is paid fully and all transfer costs are covered by them, so we receive the whole amount without withholdings. The client may also send us screenshot of the payment confirmation which will be confirmed after us receiving the amount.</p>
                <h4 className='cadTermsModifications cadTemsAccessHeading'>Changes and Modifications</h4>
                <p className='cadTermsPaymentPara'>Minor modifications and changes are free of charge. But any major change or request after the model has been finished will be charged accordingly (the price will be discussed and explained to the client, and the process will start only after confirmation).</p>
                <h4 className='cadTermsQualityControl cadTemsAccessHeading'>Quality Control</h4>
                <p className='cadTermsPaymentPara'>When the 3D Modelling process is done, the files go through quality control with special softwares. Our 3D model files are compatible with most of the 3D Printers so you don’t have to face any problems with the further manufacturing process.</p>
                <p className='cadTermsPaymentPara'>After the models are 3D Printed, they are inspected for the further manufacturing process, i.e., the Blue Resin for cutting of Dye Molds and the Castable Resin for casting the piece directly.</p>
                <h4 className='cadTermsQuantity cadTemsAccessHeading'>Confidentiality and Privacy</h4>
                <p className='cadTermsPaymentPara'>All your project ideas, concepts and data are safe with us, we give full guarantee of the confidentiality and privacy of your concepts, models, renders and prints. Your products will never be resold or misused from our end. We also offer a sign a Mutual Non-Disclosure NDA Agreement. We guarantee the intellectual property rights of the customer.</p>
                <h4 className='cadTermsWebLink cadTemsAccessHeading'>Links To Other Web Sites</h4>
                <p className='cadTermsPaymentPara'>Our Service may contain links to third-party web sites or services that are not owned or controlled by CAD AND RENDER.</p>
                <p className='cadTermsPaymentPara'>CAD AND RENDER has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that CAD AND RENDER shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
                <p className='cadTermsPaymentPara'>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>
                <h4 className='cadTermsLaw cadTemsAccessHeading'>Governing Law</h4>
                <p className='cadTermsPaymentPara'>These Terms, Terms and Conditions shall be governed and construed in accordance with the laws of JAIPUR, INDIA jurisdiction, without regard to its conflict of law provisions.</p>
                <p className='cadTermsPaymentPara'>Our failure to enforce any right or provision of these Terms, Terms and Conditions will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms, Terms and Conditions will remain in effect. These Terms, Terms and Conditions constitute the entire agreement between us regarding our Services, and supersede and replace any prior agreements we might have between us regarding the Services.</p>
                <h4 className='cadTermsTTC cadTemsAccessHeading'>Changes in Terms, Terms and Conditions</h4>
                <p className='cadTermsPaymentPara'>We reserve the right, at our sole discretion, to modify or replace these Terms, Terms and Conditions at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
                <p className='cadTermsPaymentPara'>By continuing to access or use our Services after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Services.</p>
                <h4 className='cadTermsContact cadTemsAccessHeading'>Contact Us</h4>
                <p className='cadTermsPaymentPara'>If you have any questions about these Terms, Terms and Conditions please contact us at <a href="mailto:info@cadandrender.com" target="_blank" rel='noreferrer'>info@cadandrender.com</a></p>

            </div>
        </div>

        <div className='cadContactForm cadAbout'>
            <div className='container'>
                <h2>CONTACT US</h2>
            </div>
            <div className='row cadContactFormAlign'>
                <div className='cadContactFormContent cadServicesListContent col-6'>
                    <div className='cadContactFormImg cadServicesInnerImage'>
                        <NavLink to="/contact-us">
                            <img 
                            src={`${fileUrl}/T&C-contact-form.webp`}
                            alt="t&c-contact-form" />
                        </NavLink>
                    </div>
                </div>
                <Contactform />
            </div>
        </div>
        
        <PaymentMethods />
        <Footer />
        </>
    );
}


export default TermsConditions;
