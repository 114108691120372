import React,{ useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Contactform from '../components/Contactform';
import Header from '../components/Header';
import Footer from '../components/Footer';
import TestimonialSlider from '../components/TestimonialSlider';
import ProjectsCompleted from '../components/ProjectsCompleted';
import PaymentMethods from '../components/PaymentMethods';
import CookiesPopup from '../components/CookiesPopup';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';

import Title from "../components/Title";

const fileUrl = "https://www.cadandrender.com/files";

const About = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);
        return(
            <>
            <Title title="About Us | CAD AND RENDER" />
            <Header />
            <div className='cadAboutUsMainBanner cadAboutBanner cadContactBanner'>
                <img 
                src={`${fileUrl}/about-banner.webp`}
                alt="cadandrender-about-us" />
                <div className='container'>
                    <div className='cadContactBannerContent'>
                        <h1>About Us</h1>
                    </div>
                </div>
            </div>
            <ContactIcon />
            <WhatsappChat />
            <ScrollTop />
            <CookiesPopup />

            <div className='cadAboutContent'>
                <div className='container'>
                    <p>At CAD and RENDER, we specialize in providing high-quality jewelry 3D CAD Designing and Realistic Rendering services to jewelry manufacturers, wholesalers and retailers. With years of experience in the industry, we have become experts in our craft and are dedicated to providing exceptional service to our clients.</p>
                </div>
            </div>

            <div className='cadAboutOwner'>
                <div className='container'>
                    <div className='row cadAboutOwnerAlign'>
                        <div className='cadAboutOwnerLeft col-12'>
                            <div className='cadAboutOwnerLeftInner'>
                                <div className='cadAboutOwnerImg'>
                                    <img 
                                    src={`${fileUrl}/inst.webp`}
                                    alt="cadandrender-owner" />
                                </div>
                                <h3>KUNAL REWADIA</h3>
                                <h4>FOUNDER</h4>
                                <p>Kunal is an experienced and passionate jewelry designer with a deep understanding of Computer-Aided Design (CAD) technology. With over 10 years of experience in the jewelry industry, he offers a wealth of knowledge and expertise for you to learn from. He has honed his skills through years of hands-on experience and continuous learning.</p>
                                <p>As a Jewelry CAD expert, Kunal has expertise in CAD software Rhinoceros and Matrix. He is well-versed in the intricacies of the platforms and can effectively guide learners in their journey to master CAD for jewelry design. His work in the jewelry industry spans across various styles, from traditional to contemporary and custom designs. He has an eye for detail and a unique ability to combine artistic creativity with the precision of CAD technology.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='cadAboutParas cadAboutContent'>
                <div className='container'>
                    <p>At CAD and RENDER, we specialize in providing high-quality jewelry 3D CAD Designing and Realistic Rendering services to jewelry manufacturers, wholesalers and retailers. With years of experience in the industry, we have become experts in our craft and are dedicated to providing exceptional service to our clients.</p>
                    <p>Our rendering services are also second to none. We use state-of-the-art software to create photorealistic images and realistic animation videos of jewelry designs, which can be used for promotion and marketing, sales, portfolios, etc. Our team pays attention to every detail, from the materials used to the color and texture of the piece, to create images and videos that accurately reflect the final product.</p>
                    <p>At CAD and RENDER, we pride ourselves on our commitment to quality and customer satisfaction. We understand the importance of clear communication and timely delivery, and we work closely with our clients to ensure that their needs are met every step of the way.</p>
                    <p>If you're looking for a reliable and skilled partner for your jewelry CAD and Rendering needs, look no further than CAD and RENDER. Contact us today to learn more about our services and how we can help bring your jewelry designs to life.</p>
                </div>
            </div>
            
            <div className='cadContactForm cadAbout'>
                <div className='container'>
                    <h2>CONTACT US</h2>
                </div>
                <div className='row cadContactFormAlign'>
                    <div className='cadContactFormContent cadServicesListContent col-6'>
                        <div className='cadContactFormImg cadServicesInnerImage'>
                            <NavLink to="/contact-us">
                                <img 
                                src={`${fileUrl}/contact-form-about.webp`}
                                alt="cadandrender-owner" />
                            </NavLink>
                        </div>
                    </div>
                    <Contactform />
                </div>
            </div>

            <div className='cadAboutTestimonials cadTestimonials cadAbout'>
                  <div className='container'>
                        <h2>PEOPLE ABOUT US</h2>
                        <TestimonialSlider />
                  </div>
            </div>

            <ProjectsCompleted />

            <PaymentMethods />
         
            <Footer />
           
            </>
        )
}


export default About;