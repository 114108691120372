import React, {useState, useEffect} from 'react';
import axios from 'axios';


const GalleryVideos = () => {
    const [noOfElements, setnoOfElement] = useState(20);

    const loadMore = () => {
        setnoOfElement(noOfElements + 20);
    }

    const[galleryvideos,setGalleryvideos]= useState({ Videos: [] });
    
    useEffect(()=>{
      axios.get("https://www.cadandrender.com/adminPanel/API/videos.php").then((response)=>{
        setGalleryvideos({Videos:response.data.Videos});
    })
    },[]);

    return(
        <>
        <div className='galleryVideosContent clearfix'>
            {
                galleryvideos.Videos.slice(0, noOfElements).map((res, index)=>{
                    return(
                        <div className="galleryVideosContentInner col-4" key={index}>
                            <div className='embed-container'>
                                <iframe title="videos" src={res.VideoGallery_VideoUrl} frameborder="0" allowfullscreen="allowfullscreen"></iframe>
                            </div>
                        </div>
                    )
                })
            } 
        </div>
        <button 
                style={{ display: noOfElements >= galleryvideos.Videos.length ? 'none' : 'block' }}
                className="loadmoreVideosBtn loadmoreGalleryBtn" onClick={() => loadMore()}>LOAD MORE</button>
        </>
    );
}

export default GalleryVideos;