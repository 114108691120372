import React, {useState, useEffect} from 'react';
import axios from 'axios';

const Accordians = () => {
    const [openIndex, setOpenIndex] = useState(0); 

    const[faq,setFaq]= useState([]);
    
    useEffect(()=>{
        axios.get("https://www.cadandrender.com/adminPanel/API/faqs.php").then((response)=>{
        setFaq(response.data.Faqs);
        })
	},[]);

  const handleAccordionToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
    return(
        <>
        <div className="cad-faq-accordian">
            {faq.map((res, index) => (
                <div
                key={index}
                className={`cad-faq-accordian-item ${openIndex === index ? 'active' : ''}`}
                >
                    <button
                        className="cad-faq-accordian-question"
                        onClick={() => handleAccordionToggle(index)}
                    >
                        {res.Faqs_Question}
                    </button>
                    <div
                        className={`cad-faq-accordian-answer ${openIndex === index ? 'active' : ''}`}
                        dangerouslySetInnerHTML={{ __html: res.Faqs_Answer }}
                    />
                </div>
            ))}
        </div>
        </>
    )
}


export default Accordians;