import React,{useEffect} from 'react';

import HeaderSec from '../components/HeaderSec';
import Footer from '../components/Footer';
import WhatsappChat from '../components/WhatsappChat';
import ScrollTop from '../components/ScrollTop';
import ContactIcon from '../components/ContactIcon';

const fileUrl = "https://www.cadandrender.com/files";

const Paytm = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant"
        });
      }, []);

    return(
        <>
        <HeaderSec />
        <ContactIcon />
        <WhatsappChat />
        <ScrollTop />

        <div className='cad-payment-pg'>
            <div className='container'>
                <div className='row cad-payment-pg-align'>
                    <div className='cad-payment-pg-inner col-6'>
                        <h1>Scan<br />QR Code</h1>
                    </div>
                    <div className='cad-payment-pg-inner col-6'>
                        <div className='cad-payment-pg-scan-img'>
                            <img 
                            src={`${fileUrl}/paytm-scan.webp`}
                            alt="paytm-scanner" />
                            <div className='cad-payment-tag'>
                                <p>Scan the QR code</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
        </>
    )
}

export default Paytm;