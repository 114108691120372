import React, {useState, useEffect} from 'react';

const ProjectsCompleted = () => {

    const [count, setCount] = useState(0);
    const targetValue = 4500;
    const duration = 2000;
    const increment = (targetValue / duration) * 10;

    useEffect(() => {
        let currentCount = 0;
        const timer = setInterval(() => {
          if (currentCount < targetValue) {
            currentCount += increment;
            setCount(Math.min(currentCount, targetValue));
          }
        }, 10);
        return () => {
          clearInterval(timer);
        };
    }, [increment, targetValue]);

        return(
            <>
            <div className="cadCompletedProjects">
                <div className="container">
                    <div className="cadCompletedProjectsOuter">
                        <h2>Projects Completed</h2>
                        <span>{count.toFixed(0)}+</span>
                    </div>
                </div>
            </div>
            </>
        )
} 


export default ProjectsCompleted;